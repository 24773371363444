import { ChannelCode } from "src/types/payment";

export default function filterVirtualAccounts(
  virtualAccounts: string[]
): ChannelCode[] {
  if (!virtualAccounts) return [];

  const filteredVirtualAccounts = virtualAccounts
    ?.map((item) => {
      switch (item) {
        case "BCA":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/BCA.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "BNI":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/BNI.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "BRI":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/briva.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "BJB":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/BJB.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "MANDIRI":
          return {
            channel_code: item,
            title: `Mandiri Virtual Account`,
            icon: require("src/assets/banks/Mandiri.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "PERMATA":
          return {
            channel_code: item,
            title: `Permata Virtual Account`,
            icon: require("src/assets/banks/permata.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "BSI":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/BSI.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "CIMB":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/CIMB.jpg"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "SAHABAT_SAMPOERNA":
          return {
            channel_code: item,
            title: `BSS Virtual Account`,
            icon: require("src/assets/banks/BSS.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "ARTAJASA":
          return {
            channel_code: item,
            title: `Artajasa Virtual Account`,
            icon: require("src/assets/banks/artajasa.jpg"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "BNC":
          return {
            channel_code: item,
            title: `${item} Virtual Account`,
            icon: require("src/assets/banks/BNC.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        case "MUAMALAT":
          return {
            channel_code: item,
            title: `Muamalat Virtual Account`,
            icon: require("src/assets/banks/Muamalat.png"),
            type: "VIRTUAL_ACCOUNT",
          };
        default:
          return undefined;
      }
    })
    .filter((item) => item !== undefined) as ChannelCode[];

  return filteredVirtualAccounts;
}
