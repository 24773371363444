import { useState, useEffect } from "react";
import { Endpoint, ErrorType } from "../types/general";

const replaceParams = (path: string, params?: (string | number)[]) => {
  if (!params) return path;
  let index = 0;
  return path.replace(/:(\w+)/g, () => {
    const value = params[index++];
    return value !== undefined ? encodeURIComponent(value) : "";
  });
};

const useFetch = <T>(
  endpoint: Endpoint,
  options?: {
    params?: (string | number)[];
    queryParams?: { [key: string]: string };
  }
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    setError(null);

    const searchParams = new URLSearchParams(window.location.search);
    const access_token = searchParams.get("access_token");

    const fetchData = async () => {
      try {
        const { baseUrl, path, method = "GET" } = endpoint;
        let url = `${baseUrl}${replaceParams(path, options?.params)}`;

        if (options?.queryParams) {
          const queryString = new URLSearchParams(
            options.queryParams
          ).toString();
          url += `?${queryString}`;
        }

        const response = await fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "X-Channel": "cust_mobile",
          },
          signal,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const result = await response.json();
        if (isMounted) {
          setData(result?.data);
        }
      } catch (err) {
        if (isMounted && !signal.aborted) {
          setError(
            (err as ErrorType)?.message ??
              (err as ErrorType)?.stat_msg ??
              "An error occurred"
          );
        }
      } finally {
        if (isMounted && !signal.aborted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return { data, loading, error };
};

export default useFetch;
