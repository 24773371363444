const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

const endpoints = {
  GET_VA_CODES: { baseUrl, path: "xendit/channel_code/va" },
  GET_EWALLET_CODES: { baseUrl, path: "xendit/channel_code/ewallet" },
  GET_CHANNEL_CODES: { baseUrl, path: "xendit/channel_code" },
  GET_TRANSACTION_DETAIL: { baseUrl, path: "xendit/transaction/:trx_id" },
};

export default endpoints;
