import "./dashboard.scss";
import web from "../assets/web.png";
import tokopedia from "../assets/tokopedia.png";
import blibli from "../assets/blibli.png";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import tiktok from "../assets/tiktok.png";
import savartBg from "../assets/savart-bg.jpg";
import savartLogoText from "../assets/logo_savart_text.png";

const menu = [
  {
    sublabel: "SAVART S-1 Series",
    label: "Flyer",
    link: "/Flyer SAVART S-1 .pdf",
    download: true,
  },
  {
    sublabel: "SAVART S-1 Series",
    label: "Product Introduction",
    link: "/Product Introduction SAVART S-1.pdf",
    download: true,
  },
  {
    label: "Company Profile",
    link: "/Company Profile 2025.pdf",
    download: true,
  },
  // { label: "Test Ride", link: "https://forms.gle/ZHAe2DW8jDC7jHEU7" },
  // { label: "Pre-Order", link: "https://forms.gle/h6bKysGy6wzqGrpM9" },
];

const menu2 = [
  {
    label: "Web",
    link: "https://savart-ev.com/",
    image: web,
    imgClassname: "h-6",
  },
  {
    label: "Tokopedia",
    link: "https://www.tokopedia.com/savart-motors",
    image: tokopedia,
    imgClassname: "h-11",
  },
  {
    label: "Blibli",
    link: "https://www.blibli.com/merchant/savart-motor-indonesia-official-store/SAM-70396?pickupPointCode=PP-3512307",
    image: blibli,
    imgClassname: "h-9",
  },
];

const socmed = [
  {
    label: "Instagram",
    link: "https://www.instagram.com/savartmotors/",
    image: instagram,
    imgClassname: "h-5",
  },
  {
    label: "Facebook",
    link: "https://www.facebook.com/savartmotors/",
    image: facebook,
  },
  {
    label: "TikTok",
    link: "https://www.tiktok.com/@savartmotors",
    image: tiktok,
  },
];

export default function DashboardPage() {
  return (
    <main
      className="relative flex flex-col justify-between w-screen"
      style={{ height: window.innerHeight }}
    >
      <div className="flex items-center justify-center p-12 sm:px-32">
        <img src={savartLogoText} alt="SAVART Logo" className="w-[125px]" />
      </div>
      <img
        src={savartBg}
        alt="savart-bg"
        className="absolute top-0 left-0 object-cover w-full h-full vignette -z-10"
      />
      <div className="absolute w-full h-full -z-10 vignette" />
      <div className="p-12 sm:px-32">
        <h1 className="text-white poppins-regular subtitle">Welcome to</h1>
        <h1 className="text-white poppins-bold title">SAVART Motors</h1>

        <div className="flex flex-col gap-3 mt-5">
          {menu.map((item, idx) => (
            <a
              href={item.link}
              download={item?.download}
              key={idx}
              className="text-center no-underline hover:cursor-pointer text-sm poppins-bold w-full h-12 text-[#ffffff] rounded-full border border-white hover:bg-white hover:text-black transition-all"
            >
              <div className="flex flex-col justify-center h-full">
                {item.sublabel && (
                  <div className="text-[0.625rem] font-light">
                    {item.sublabel}
                  </div>
                )}
                <div>{item.label}</div>
              </div>
            </a>
          ))}
        </div>

        <div className="flex items-center justify-between mt-3 sm:gap-5 sm:justify-center">
          {menu2.map((item, idx) => (
            <a href={item.link} key={idx} className="hover:cursor-pointer">
              <img
                src={item.image}
                alt={item.label}
                className={item.imgClassname ?? "h-6"}
              />
            </a>
          ))}
        </div>

        <div className="flex flex-row items-center gap-4 justify-center mt-[3rem]">
          <div className="text-xs text-white">Our Social Media</div>
          {socmed.map((item, idx) => (
            <a key={idx} href={item.link} className="hover:cursor-pointer">
              <img
                src={item.image}
                alt={item.label}
                className={item.imgClassname ?? "h-6"}
              />
            </a>
          ))}
        </div>
      </div>
    </main>
  );
}
