import { useCallback, useEffect, useState } from "react";

const useCountdown = (date?: string): string => {
  const calculateTimeLeft = useCallback((): string => {
    const targetTime = new Date(date ?? "").getTime();
    const now = new Date().getTime();
    const difference = targetTime - now;

    if (difference <= 0) return "00:00:00";

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }, [date]);

  const [timeLeft, setTimeLeft] = useState<string>(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [date, calculateTimeLeft]);

  return timeLeft;
};

export default useCountdown;
