import { ChannelCode } from "src/types/payment";

export default function filterEwallet(ewallets: string[]): ChannelCode[] {
  const filteredEwallets = ewallets
    .map((item) => {
      switch (item) {
        case "OVO":
          return {
            channel_code: item,
            title: item,
            icon: require("src/assets/ewallet/ovo.png"),
            type: "EWALLET",
          };
        case "DANA":
          return {
            channel_code: item,
            title: item,
            icon: require("src/assets/ewallet/dana.png"),
            type: "EWALLET",
          };
        case "LINKAJA":
          return {
            channel_code: item,
            title: "Link Aja",
            icon: require("src/assets/ewallet/LinkAja.png"),
            type: "EWALLET",
          };
        case "SHOPEEPAY":
          return {
            channel_code: item,
            title: "Shopee Pay",
            icon: require("src/assets/ewallet/ShopeePay.png"),
            type: "EWALLET",
          };
        case "JENIUSPAY":
          return {
            channel_code: item,
            title: "Jenius Pay",
            icon: require("src/assets/ewallet/JeniusPay.png"),
            type: "EWALLET",
          };
        case "ASTRAPAY":
          return {
            channel_code: item,
            title: "Astra Pay",
            icon: require("src/assets/ewallet/AstraPay.jpeg"),
            type: "EWALLET",
          };
        default:
          return undefined;
      }
    })
    .filter((item) => item !== undefined) as ChannelCode[];

  return filteredEwallets;
}
