import {
  LuWallet,
  LuCopy,
  LuClock,
  LuRefreshCw,
  LuCircleHelp,
} from "react-icons/lu";
import { useParams } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { TransactionDetail } from "src/types/payment";
import endpoints from "src/utils/endpoints";
import { formatCurrency } from "src/utils/helpers";
import toast from "react-simple-toasts/./dist";
import useCountdown from "src/hooks/useCountdown";
import { useCallback } from "react";

type Props = {
  lang: "en" | "id";
};

export default function PostPaymentPage({ lang }: Props) {
  const { transactionId } = useParams<{ transactionId: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const access_token = searchParams.get("access_token");

  const { data, loading } = useFetch<TransactionDetail>(
    endpoints.GET_TRANSACTION_DETAIL,
    {
      params: [transactionId as string],
    }
  );

  const handleCheckStatus = useCallback(async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}xendit/transaction/${transactionId}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
          "X-Channel": "cust_mobile",
        },
      });

      if (response.ok) {
        const responseBody = await response.json();
        const data: TransactionDetail = responseBody?.data;
        if (data?.payment_request?.status === "SUCCEEDED") {
          window.location.href = `savart://PostPayment?success=true&type=${
            data?.zuttoride_product ? "ERA" : "SWAP"
          }`;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [transactionId, access_token]);

  const countdown = useCountdown(
    data?.payment_request?.payment_method?.virtual_account?.channel_properties
      ?.expires_at
    //data?.payment_request?.payment_method?.ewallet?.channel_properties?.expires_at
  );

  const handleCopy = async (text: string | number) => {
    if (!navigator.clipboard) return;

    await navigator.clipboard.writeText(String(text));
    toast(lang === "en" ? "Copied to clipboard" : "Tersalin ke clipboard");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="relative flex flex-col items-center min-h-screen overflow-hidden bg-gray-50">
      {/* Card */}
      <div className="flex flex-col justify-between flex-1 w-full max-w-md rounded-t-lg">
        <div>
          {/* Card Header */}
          <header className="p-4 bg-primary">
            <h1 className="text-xl font-bold text-white">
              {lang === "en" ? "Payment Detail" : "Detail Pembayaran"}
            </h1>
            <p className="text-white">
              {lang === "en"
                ? "Complete your payment before the time expires"
                : "Selesaikan pembayaran Anda sebelum waktu berakhir"}
            </p>
          </header>

          <div className="p-4">
            <div className="flex flex-col items-center py-3 rounded bg-primary-light">
              {lang === "en" ? "Amount to Pay" : "Jumlah yang Harus Dibayarkan"}
              <p className="mt-1 text-xl font-bold">
                {formatCurrency(data?.price)}
              </p>
            </div>

            <h2 className="mt-4 mb-2 font-semibold">
              {data?.payment_request?.payment_method?.type === "VIRTUAL_ACCOUNT"
                ? lang === "en"
                  ? "Virtual Account Number"
                  : "Nomor Virtual Account"
                : lang === "en"
                ? "Payment Method"
                : "Metode Pembayaran"}
            </h2>
            <div
              className={`flex items-center ${
                data?.payment_request?.payment_method?.type ===
                "VIRTUAL_ACCOUNT"
                  ? "justify-between"
                  : "justify-center"
              } px-3 py-2 rounded bg-primary-light gap-2`}
            >
              {data?.payment_request?.payment_method?.type !==
                "VIRTUAL_ACCOUNT" && <LuWallet size={24} />}
              <p className="text-lg font-medium">
                {data?.payment_request?.payment_method?.type ===
                "VIRTUAL_ACCOUNT"
                  ? data?.payment_request?.payment_method?.virtual_account
                      ?.channel_properties?.virtual_account_number
                  : data?.payment_request?.payment_method?.type === "EWALLET"
                  ? data?.payment_request?.payment_method?.ewallet?.channel_code
                  : ""}
              </p>
              {data?.payment_request?.payment_method?.type ===
                "VIRTUAL_ACCOUNT" && (
                <div
                  onClick={() =>
                    handleCopy(
                      data?.payment_request?.payment_method?.virtual_account
                        ?.channel_properties?.virtual_account_number
                    )
                  }
                  className="p-2 rounded-md hover:bg-slate-200 hover:cursor-pointer"
                >
                  <LuCopy size={24} />
                </div>
              )}
            </div>

            <h2 className="mt-4 mb-2 font-semibold">
              {lang === "en" ? "Time Remaining" : "Waktu Tersisa"}
            </h2>
            <div className="flex items-center justify-center gap-2 px-3 py-2 rounded bg-primary-light">
              <div className="p-2 rounded-md hover:bg-slate-200 hover:cursor-pointer">
                <LuClock size={24} />
              </div>
              <p className="text-lg font-medium">{countdown}</p>
            </div>
          </div>
        </div>

        <div className="">
          <hr className="w-full border-t text-slate-500" />
          <div className="flex flex-col gap-2 p-4">
            <button
              onClick={() => {}}
              className="flex items-center justify-center w-full gap-2 py-2 mt-auto font-semibold transition-colors border rounded border-slate-500"
            >
              <LuCircleHelp size={20} />
              {lang === "en" ? "How to Pay" : "Cara Pembayaran"}
            </button>
            <button
              onClick={handleCheckStatus}
              className="flex items-center justify-center w-full gap-2 py-2 mt-auto text-white transition-colors rounded pay-button"
            >
              <LuRefreshCw size={20} />
              <p>
                {lang === "en"
                  ? "Check Payment Status"
                  : "Cek Status Pembayaran"}
              </p>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
