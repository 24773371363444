import { useNavigate, useParams } from "react-router-dom";
import { LuCreditCard } from "react-icons/lu";
import "./payment.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import {
  ChannelCode,
  GetChannelCodeResponse,
  OnPayResponse,
  TransactionDetail,
} from "src/types/payment";
import useFetch from "src/hooks/useFetch";
import endpoints from "src/utils/endpoints";
import { formatCurrency } from "src/utils/helpers";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import filterVirtualAccounts from "src/utils/filterVirtualAccounts";
import filterEwallet from "src/utils/filterEwallet";

// http://localhost:3000/en/payment/1?access_token=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMCIsImFjY2Vzc191dWlkIjoiNzExYTJjYzktNDVkMi00MWQ1LTk4NGMtZjNjNGJmMDYwZTc2Iiwicm9sZSI6ImlzY29vdF9tb2JpbGUiLCJlbWFpbCI6IiIsImNoYW5uZWwiOiJjdXN0X21vYmlsZSIsImV4cCI6MTc0MTA2MzU3MH0.iqMBnyY5E5JvLVHmufrJbE23TCvKOCf-t8OOBMAm2x3eMIUivBoCig37E5iVM9ThRb6cMSzwEjH2SRjnkR7XGA&amount=3600000&sku=Sepeda%20Motor&product_id=1&product_price_condition_id=2&product_price_id=3

type Props = {
  lang: "en" | "id";
};

type PaymentMethod = {
  title: string;
  isOpen: boolean;
  channelCodes: ChannelCode[];
};

export default function PaymentPage({ lang }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const { transactionId } = params;
  const searchParams = new URLSearchParams(window.location.search);
  const access_token = searchParams.get("access_token");

  const { data } = useFetch<GetChannelCodeResponse>(
    endpoints.GET_CHANNEL_CODES
  );
  const { data: transactionDetail, loading } = useFetch<TransactionDetail>(
    endpoints.GET_TRANSACTION_DETAIL,
    {
      params: [transactionId as string],
    }
  );

  const paymentMethodsData: PaymentMethod[] = useMemo(
    () => [
      {
        title: "Virtual Account",
        isOpen: false,
        channelCodes: filterVirtualAccounts(data?.va_channel_code ?? []),
      },
      {
        title: "E-Wallet",
        isOpen: false,
        channelCodes: filterEwallet(data?.ewallet_channel_code ?? []),
      },
    ],
    [data?.ewallet_channel_code, data?.va_channel_code]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [selectedChannelCode, setSelectedChannelCode] =
    useState<ChannelCode | null>(null);

  const handleSelectPaymentMethod = useCallback(
    (method: PaymentMethod) => {
      const newValue = paymentMethods;

      newValue.forEach((value) => {
        if (value?.channelCodes === method?.channelCodes) {
          value.isOpen = !value.isOpen;
        }
      });

      setPaymentMethods([...newValue]);
    },
    [paymentMethods]
  );

  const handleOnPay = useCallback(async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}xendit/transaction/${transactionId}`;
    if (selectedChannelCode === null) {
      alert("Please select payment method");
      return;
    }
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
          "X-Channel": "cust_mobile",
        },
        body: JSON.stringify({
          payment_method: selectedChannelCode?.type,
          channel_code: selectedChannelCode?.channel_code,
        }),
      });

      if (response.ok) {
        const responseBody = await response.json();
        const data: OnPayResponse = responseBody?.data;
        if (data?.payment_method?.type === "EWALLET") {
          window.location.href = data?.actions?.[1]?.url;
        } else {
          navigate(
            `/${lang}/post-payment/${transactionId}?access_token=${access_token}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [transactionId, access_token, selectedChannelCode, lang, navigate]);

  useEffect(() => {
    setPaymentMethods(paymentMethodsData);
  }, [paymentMethodsData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="relative flex flex-col items-center min-h-screen overflow-hidden bg-gray-50">
      {/* Card */}
      <div className="w-full max-w-md rounded-t-lg">
        {/* Card Header */}
        <header className="p-4 bg-primary">
          <div className="flex items-center gap-2">
            <LuCreditCard className="w-6 h-6 text-white" />
            <h1 className="text-xl font-bold text-white">
              {lang === "en" ? "Payment Request" : "Permintaan pembayaran"}
            </h1>
          </div>
          <p className="text-white">
            {lang === "en"
              ? "Please complete your payment"
              : "Harap selesaikan pembayaran Anda"}
          </p>
        </header>

        {/* Card Content */}
        <div className="p-4 text-sm">
          <p className="mb-2 text-slate-500">
            {lang === "en" ? "Product Details" : "Detail Produk"}
          </p>
          <div className="flex items-center justify-between">
            <p>{lang === "en" ? "Name:" : "Nama:"}</p>
            <p>
              {transactionDetail?.battery_swap_product
                ? lang === "en"
                  ? transactionDetail?.battery_swap_product?.product_name_en
                  : transactionDetail?.battery_swap_product?.product_name_id
                : transactionDetail?.zuttoride_product
                ? transactionDetail?.zuttoride_product?.product_name
                : ""}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <p>{lang === "en" ? "Payment Method:" : "Metode Pembayaran:"}</p>
            {/* todo: dummy */}
            <button
              onClick={() => setIsOpen(true)}
              className="flex items-center"
            >
              <p className="tex-xs">
                {selectedChannelCode?.title
                  ? selectedChannelCode?.title
                  : lang === "en"
                  ? "select"
                  : "pilih"}
              </p>
              <MdChevronRight className="text-primary" size={20} />
            </button>
          </div>

          <hr className="my-3 rounded-full text-slate-500" />

          <p className="text-slate-500">
            {lang === "en" ? "Amount to Pay" : "Jumlah yang Harus Dibayarkan"}
          </p>
          {/* todo: dummy */}
          <p className="mt-2.5 text-xl font-bold text-center">
            {formatCurrency(transactionDetail?.price)}
          </p>

          <hr className="my-3 rounded-full text-slate-500" />

          {/* Payment Details */}
          <button
            onClick={handleOnPay}
            className="w-full py-1.5 text-lg font-semibold text-white transition-colors rounded pay-button"
          >
            {lang === "en" ? "Pay Now" : "Bayar Sekarang"}
          </button>

          <p className="mt-2 text-xs text-center">
            {lang === "en"
              ? "Please complete the payment process"
              : "Silakan selesaikan proses pembayaran"}
          </p>
        </div>
      </div>

      {/* Payment Sheet */}
      <div
        className={`flex flex-col justify-between overflow-y-auto p-4 bg-white absolute top-0 left-0 w-full h-screen transition-all ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button onClick={() => setIsOpen(false)} className="flex items-center">
          <MdChevronLeft size={20} className="text-primary" />
          <p className="tex-xs">{lang === "en" ? "back" : "kembali"}</p>
        </button>
        <h1 className="mt-4 mb-2 text-xl font-bold">
          {lang === "en" ? "Select Payment Method" : "Pilih Metode Pembayaran"}
        </h1>

        {/* Payment Method */}
        {paymentMethods.map((method, idx) => (
          <div className="faq-list" key={idx}>
            <div
              className="faq-question"
              onClick={() => handleSelectPaymentMethod(method)}
            >
              <div className="text">{method?.title}</div>
              <div className={"icon" + (method?.isOpen ? " icon-open" : "")}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
            <Collapse in={method.isOpen}>
              <div className="mt-2">
                {method.channelCodes.map((channelCode, idx) => (
                  <label
                    key={idx}
                    className="flex items-center justify-between gap-2 mb-4 text-sm cursor-pointer"
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={channelCode?.icon}
                        alt="Bank Icon"
                        className="object-contain h-5"
                      />
                      <p>{channelCode.title}</p>
                    </div>
                    <input
                      type="radio"
                      name="channelCode"
                      value={channelCode?.channel_code}
                      checked={
                        selectedChannelCode?.channel_code ===
                        channelCode?.channel_code
                      }
                      onChange={() => setSelectedChannelCode(channelCode)}
                      className="w-4 h-4 cursor-pointer accent-blue-500"
                    />
                  </label>
                ))}
              </div>
            </Collapse>
            {paymentMethods.length - 1 !== idx && (
              <hr style={{ opacity: 0.1 }} />
            )}
          </div>
        ))}

        <button
          onClick={() => setIsOpen(false)}
          className="mt-auto w-full py-1.5 text-lg font-semibold text-white transition-colors rounded pay-button"
        >
          {lang === "en" ? "Confirm" : "Konfirmasi"}
        </button>
      </div>
    </main>
  );
}
