import "./styles.scss";
import Router from "./router";
import { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/style.css";
import "react-simple-toasts/dist/theme/dark.css";

toastConfig({
  theme: "dark",
});

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
